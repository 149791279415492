import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const PreviewCompatibleImage = ({ imageInfo }) => {
  const { alt = '', title = '', image } = imageInfo;
  const imgChild = getImage(image);
  if (!!image && !!image.childImageSharp) {
    return (
      <GatsbyImage
        image={imgChild}
        alt={title || alt}
        object-fit="cover"
        width="100%"
        height="100%"
      />
    );
  }

  return null;
};

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }).isRequired,
};

export default PreviewCompatibleImage;
