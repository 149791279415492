import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import Layout from '../../components/Layout';
import BlogRoll from '../../components/BlogRoll';
import SectionHeader from '../../components/SectionHeader';

const ImageContainer = styled.div`
  width: 100vw;
  height: 300px;
  position: relative;
  background-size: cover;
  background-position: 100% 80%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AbsolutePositionHeader = styled(SectionHeader)`
  position: absolute;
  top: 20%;
  left: 0;
`;

const BlogIndexPage = () => {
  return (
    <Layout>
      <ImageContainer>
        <StaticImage
          src="../../img/ciezarowka-w-trasie.jpg"
          alt="Trasport ciężarowy"
          style={{ width: '100%', height: '100%' }}
          layout="fullWidth"
        />
        <AbsolutePositionHeader title="Ostatnie artykuły" color="#F4F4F4" />
      </ImageContainer>
      <BlogRoll />
    </Layout>
  );
};

export default BlogIndexPage;
