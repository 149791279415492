import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { graphql, StaticQuery } from 'gatsby';
import { Clock } from '@styled-icons/fa-solid';

import PreviewCompatibleImage from './PreviewCompatibleImage';
import Anchorlink from './Anchorlink';
import SectionHeader from './SectionHeader';

const BlogSection = styled.section`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  ${({ theme }) => theme.mq.lg} {
    flex-direction: row;
    padding: 50px;
  }
`;

const Article = styled.article`
  width: 100%;
  padding: 5px;
  position: relative;
  background: ${({ theme }) => theme.color.one};
  border-radius: 0px;
  visibility: hidden;
  ${({ theme }) => theme.mq.md} {
    margin: 0 auto;
    width: 80%;
  }
  ${({ theme }) => theme.mq.lg} {
    flex: 0 0 calc(100vw / 3 - 100px);
    width: calc(100vw / 3 - 100px);
    padding: 15px;
  }
`;

const ImageContainer = styled.div`
  height: 300px;
  overflow: hidden;
`;

const ArticlePreview = styled.div`
  padding: 10px 0;
`;

const MetaTags = styled.div`
  margin-bottom: 10px;
`;

const Date = styled.span`
  color: ${({ theme }) => theme.color.five};
  margin-right: 10px;
`;

const ArticleTitle = styled.h2`
  line-height: 26px;
  font-size: ${({ theme }) => theme.font.size.s};
  font-weight: 700;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.color.four};
`;

const FeatureParagraph = styled.p`
  font-size: ${({ theme }) => theme.font.size.xxs};
  color: ${({ theme }) => theme.color.five};
`;

const BlogRollDesign = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;
  const cards = useRef([]);

  cards.current = [];

  const addToRefs = (el) => {
    if (el && !cards.current.includes(el)) {
      cards.current.push(el);
    }
  };

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.set(cards.current, { autoAlpha: 0, scale: 0.9 });
    ScrollTrigger.batch(cards.current, {
      start: 'top bottom-=100px',
      onEnter: (batch) =>
        gsap.to(batch, {
          duration: 0.8,
          autoAlpha: 1,
          scale: 1,
          ease: 'none',
          stagger: 0.3,
        }),
      onLeaveBack: (batch) =>
        gsap.to(batch, {
          duration: 0.5,
          autoAlpha: 0,
          scale: 0.9,
          stagger: 0.1,
        }),
    });
  }, []);
  return (
    <BlogSection id="blog">
      <SectionHeader title="Najnowsze artykuły" />
      {posts &&
        posts.map(({ node: post }) => (
          <Article ref={addToRefs} key={post.id}>
            {post.frontmatter.featuredimage ? (
              <ImageContainer>
                <PreviewCompatibleImage
                  imageInfo={{
                    image: post.frontmatter.featuredimage,
                    alt: `${post.frontmatter.title}`,
                  }}
                />
              </ImageContainer>
            ) : null}
            <ArticlePreview>
              <MetaTags>
                <Date>
                  <Clock size={18} />
                </Date>
                <Date>{post.frontmatter.date}</Date>
              </MetaTags>
              <ArticleTitle>
                <Anchorlink to={post.fields.slug} ariaLabel="czytaj więcej">
                  {post.frontmatter.title}
                </Anchorlink>
              </ArticleTitle>
              <FeatureParagraph>
                {post.excerpt}
                <br />
                <br />
                <Anchorlink to={post.fields.slug} ariaLabel="czytaj więcej">
                  Czytaj dalej →
                </Anchorlink>
              </FeatureParagraph>
            </ArticlePreview>
          </Article>
        ))}
    </BlogSection>
  );
};

BlogRollDesign.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
};

const BlogRoll = () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "DD MMMM YYYY", locale: "pl-PL")
                featuredpost
                featuredimage {
                  childImageSharp {
                    gatsbyImageData(
                      width: 600
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRollDesign data={data} count={count} />}
  />
);

export default BlogRoll;
